<!--
 * @Author: LiuXin
 * @Date: 2021-10-13 15:29:39
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-27 18:44:25
-->
<template>
  <div class="base_info_wrapper base-info-width-img w100 h100">
    <div class="img_wrapper">
      <template v-if="imgList.length">
        <CreativeBox v-for="item in imgList" :key="item.url" v-bind="item" />
      </template>
      <a-empty v-else></a-empty>
    </div>
    <div class="baseinfo_text">
      <div class="baseInfo_group" v-for="group in groupList" :key="group.groupName">
        <p class="module_title w100">
          <span class="title_content"> {{ group.groupName }} </span>
        </p>
        <div class="info_item mb10" v-for="groupItem in group.groupItemList" :key="groupItem.label">
          <div class="info_label">{{ groupItem.label }}：</div>

          <div class="info_value" v-if="['广告名称', '出价'].includes(groupItem.label)">
            <a-popover :title="`修改${groupItem.label}`" trigger="click" :visible="groupItem.visible">
              <template #content>
                <a-textarea v-if="groupItem.label == '广告名称'" v-model:value="groupItem.editValue" show-count :maxlength="120" style="width: 240px" />
                <a-input v-else-if="groupItem.label == '出价'" v-model:value="groupItem.editValue" :suffix="setCjSuffix(baseInfo)" placeholder="请输入出价"></a-input>

                <!-- 底部按钮 -->
                <a-row type="flex" justify="space-around" class="mt10">
                  <a-button @click="groupItem.visible = false">取消</a-button>
                  <a-button type="primary" @click="editAdGroup(groupItem)">确定</a-button>
                </a-row>
              </template>
              {{ groupItem.value ?? '-' }}
              <EditOutlined class="pl10 cursorPoi" @click="groupItem.visible = true" />
            </a-popover>
          </div>

          <div class="info_value" v-else-if="groupItem.label == '日预算'">
            <a-popover title="修改广告日预算" trigger="click" :visible="groupItem.visible">
              <template #content>
                <div class="editBudget">
                  <a-radio-group v-model:value="groupItem.radio" class="mb20">
                    <a-radio value="noLimited">不限</a-radio>
                    <a-radio value="customize">自定义</a-radio>
                  </a-radio-group>
                  <div v-show="groupItem.radio == 'customize'" class="mb10">
                    <a-input v-model:value="groupItem.editValue" suffix="元/天" placeholder="请输入日预算"></a-input>
                  </div>
                </div>
                <!-- 底部按钮 -->
                <a-row type="flex" justify="space-around" class="mt10">
                  <a-button @click="groupItem.visible = false">取消</a-button>
                  <a-button type="primary" @click="editAdGroup(groupItem)">确定</a-button>
                </a-row>
              </template>
              {{ groupItem.value }}
              <EditOutlined class="pl10 cursorPoi" @click="groupItem.visible = true" />
            </a-popover>
          </div>
          <div class="info_value" v-else-if="groupItem.label == '投放时间'" v-html="setPostTime(groupItem.value)"></div>
          <div class="info_value" v-else>{{ groupItem.value ?? '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
import { EditOutlined } from '@ant-design/icons-vue';
import { updateAdName } from '@/api/popularize/txgg/index.js';
import columns from '@/views/popularize/assets/txggColumns.js';
import { getTableData } from '@/api/popularize/txgg/index.js';
export default {
  emits: ['editAdGroup'],
  components: { CreativeBox, EditOutlined },
  inject: ['getActiveKey', 'txggDict'],
  props: {
    baseInfo: { type: Object, default: () => {} },
  },
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsGDT') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      imgList: [],
      groupList: [],
      bidDictionary: columns.bidDictionary,
      adgroupName: {
        isEdit: false,
        editValue: null,
      },
      dailyBudget: {
        isEdit: false,
        editValue: null,
        radio: '',
      },
      cj: {
        isEdit: false,
        editValue: null,
        radio: '',
      },
      adStatusDict: {
        DENIED: '审核不通过',
        FROZEN: '冻结',
        SUSPEND: '暂停中.',
        READY: '未到投放时间',
        ACTIVE: '投放中',
        STOP: '投放结束',
        PREPARE: '准备中',
        DELETED: '已删除',
        ACCOUNT_FROZEN: '广告被暂停(账户资金被冻结)',
        ACCOUNT_EMPTY: '广告被暂停(账户余额不足)',
        ACCOUNT_LIMIT: '广告被暂停(账户达日限额)',
        CAMPAIGN_LIMIT: '广告被暂停(推广计划达日限额)',
        CAMPAIGN_SUSPEND: '广告被暂停(推广计划暂停)',
        AD_LIMIT: '广告被暂停(广告达日限额)',
        PART_READY: '部分待投放',
        PART_ACTIVE: '部分投放中',
      },
      systemStatusDict: {
        NORMAL: '有效',
        PENDING: '待审核',
        DENIED: '审核不通过',
        PARTIALLY_PENDING: '部分审核中',
        PARTIALLY_NORMAL: '部分有效',
        PREPARE: '准备中(当投放视频广告时，该状态代表视频转码中)',
        DELETED: '已删除',
        INVALID: '异常(当投放视频广告时，该状态代表视频转码失败)',
      },
      siteDict,
      columns,
      adType: {
        INTELLIGENCE: '优选模式',
        AVERAGE: '轮播模式',
      },
    };
  },
  watch: {
    baseInfo(val) {
      if (val.adcreative?.length) {
        this.getGroupList();
        this.getImgList();
      }
    },
  },
  methods: {
    async getImgList() {
      const params = { ...JSON.parse(sessionStorage.getItem('txggGetTableParams')), adgroup: this.baseInfo.adgroupId };
      const res = await getTableData(params, 'creativity');
      const creativityList = res.data?.list || [];
      const imgList = [];
      creativityList.forEach(item => {
        imgList.push({
          imgInfo: {
            url: item.previewUrl,
            type: item.type || 'VIDEO',
            title: item.title,
          },
          status: this.systemStatusDict[item.system_status],
        });
      });
      this.imgList = imgList;
    },
    editItem(type) {
      this[type].radio = !this.baseInfo[type] || this.baseInfo[type] == 0 ? 'noLimited' : 'customize';
      this[type].editValue = this.baseInfo[type];
      this[type].isEdit = true;
    },
    beforeDrawerClosed() {
      this.adgroupName.isEdit = false;
      this.dailyBudget.isEdit = false;
      this.cj.isEdit = false;
    },
    async editAdGroup(groupItem) {
      let res;
      let type = groupItem.label;
      switch (type) {
        case '广告名称':
          res = await updateAdName({ adgroupId: this.baseInfo.adgroupId, adgroupName: groupItem.editValue });
          break;
        case '预算':
          res = await adgroupUpdateDailyBudget([{ adGroupId: this.baseInfo.adgroupId, budget: groupItem.editValue, type: 1 }]);
          break;
        case '出价':
          res = await updateBidAmount([{ adGroupId: this.baseInfo.adgroupId, number: groupItem.editValue, type: 6 }]);
          break;
      }
      if (res.code == 0) {
        groupItem.visible = false;
      }
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */ setBidColumn(record) {
      const bidMode = record.bidMode ? this.columnMap(this.bidDictionary.bid_mode, record.bidMode) : 'CPC';
      const bidAmount = record.bidAmount || 0;
      const optimizationGoal = record.optimizationGoal ? this.columnMap(this.bidDictionary.optimization_goal, record.optimizationGoal) : '激活';
      return record.smartBidType == 'CUSTOM' ? `${bidMode} ${bidAmount + '元'}/${optimizationGoal}` : `自动出价（${bidMode}/${optimizationGoal}）`;
    },
    /** 设置出价编辑尾部提示
     * @param {*} record
     * @return {*}
     */
    setCjSuffix(record) {
      this.cj.editValue = record.bidAmount || 0;
      const optimizationGoal = record.optimizationGoal ? this.columnMap(this.bidDictionary.optimization_goal, record.optimizationGoal) : '激活';
      return '元/' + optimizationGoal;
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions.filter(item => {
          return item[key] == columnValue;
        })[0];
        return returnOpt && returnOpt[text];
      }
    },
    setPostTime(formatTimeSeries) {
      let str = '-';
      const arr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
      if (formatTimeSeries) {
        if (arr.every(item => formatTimeSeries[item].includes('不限'))) {
          str = '不限';
        } else if (arr.every(item => formatTimeSeries[item] == formatTimeSeries['星期一'])) {
          str = formatTimeSeries['星期一'];
        } else {
          str = `星期一：${formatTimeSeries?.['星期一']}<br>
                星期二：${formatTimeSeries?.['星期二']}<br>
                星期三：${formatTimeSeries?.['星期三']}<br>
                星期四：${formatTimeSeries?.['星期四']}<br>
                星期五：${formatTimeSeries?.['星期五']}<br>
                星期六：${formatTimeSeries?.['星期六']}<br>
                星期日：${formatTimeSeries?.['星期日']}<br>  `;
        }
      }
      return str;
    },
    getGroupList() {
      const directionList = this.baseInfo.targetingTranslation?.split('；') || [];
      const area = directionList.find(item => item.includes('地理位置'))?.replace('地理位置：', '');
      const age = directionList.find(item => item.includes('年龄'))?.replace('年龄：', '');
      const gender = directionList.find(item => item.includes('性别'))?.replace('性别：', '');
      const person = directionList.find(item => item.includes('自定义人群'))?.replace('自定义人群：', '');
      const groupList = [
        {
          groupName: '基本情况',
          groupItemList: [
            { label: '广告名称', value: this.baseInfo.adgroupName, editValue: this.baseInfo.adgroupName, visible: false },
            { label: '广告ID', value: this.baseInfo.adgroupId },
            { label: '推广计划', value: this.baseInfo.campaign_name },
            { label: '推广目标', value: this.txggDict.promotedObjectType(this.baseInfo.promotedObjectType) },
            { label: '广告形式', value: this.adType[this.baseInfo.creativeDisplayType] },
            { label: '投放产品', value: this.product_name },
            { label: '渠道包', value: this.appAndroidChannelPackageId },
            { label: '应用ID', value: this.baseInfo.promotedObjectId },
            { label: '版位', value: this.baseInfo.siteSet?.map(site => this.siteDict[site])?.join(' ') || this.baseInfo.automaticSiteEnabled ? '自动版位' : '-' },
            { label: '转化归因', value: this.baseInfo.key },
            { label: '营销场景', value: this.baseInfo.marketingScene },
          ],
        },
        {
          groupName: '排期与出价',
          groupItemList: [
            { label: '投放日期', value: this.baseInfo.endDate == '1970-01-01' ? `长期投放（开始日期：${this.baseInfo.beginDate}）` : `${this.baseInfo.beginDate}- ${this.baseInfo.endDate}` },
            { label: '投放时间', value: this.baseInfo.formatTimeSeries },
            { label: '首日开始时间', value: this.baseInfo.firstDayBeginTime },
            {
              label: '日预算',
              value: this.baseInfo.dailyBudget == 0 ? '不限' : this.baseInfo.dailyBudget ? `${this.baseInfo.dailyBudget} 元/天` : '-',
              editValue: this.baseInfo.dailyBudget,
              visible: false,
              radio: this.baseInfo.dailyBudget == 0 ? 'noLimited' : 'customize',
            },
            { label: '出价策略', value: this.txggDict.strategy(this.baseInfo.bidStrategy) },
            { label: '出价', value: this.setBidColumn(this.baseInfo), editValue: this.baseInfo.bidAmount, visible: false },
            {
              label: '分版位出价',
              value: this.baseInfo.bidAdjustment?.siteSetPackage
                ? this.baseInfo.bidAdjustment.siteSetPackage
                    .map(item => {
                      return `${this.txggDict.siteSet(item.siteSet?.[0])}：系数${item.bidCoefficient}`;
                    })
                    .join('\n')
                    .replace('', '已开启：', 0, 0)
                : '未开启',
            },
            ...(this.baseInfo.deepConversionSpec?.deepConversionType
              ? [
                  { label: '深度转化优化', value: this.baseInfo.deepConversionSpec?.deepConversionType ? '开启' : '未开启' },
                  { label: '深度优化类型', value: this.txggDict.deepConversionType(this.baseInfo.deepConversionSpec?.deepConversionType) },
                  { label: '深度优化目标', value: this.txggDict.optimizationGoal(this.baseInfo.deepConversionSpec?.deepConversionBehaviorSpec?.goal) ?? '-' },
                  { label: '深度目标出价', value: this.baseInfo.deepConversionSpec?.deepConversionBehaviorSpec?.bidAmount ? `${this.baseInfo.deepConversionSpec?.deepConversionBehaviorSpec?.bidAmount / 100} 元/${this.txggDict.optimizationGoal(this.baseInfo.deepConversionSpec?.deepConversionBehaviorSpec?.goal)}` : '-' },
                ]
              : [{ label: '深度转化优化', value: '未开启' }]),
            { label: '分版位深度出价', value: this.baseInfo.key ? this.baseInfo.key : '未开启' },
            { label: '一键起量', value: this.baseInfo.autoAcquisitionEnabled == 'true' ? '是' : '否' },
          ],
        },
        {
          groupName: '定向',
          groupItemList: [
            { label: '地域', value: area },
            { label: '年龄', value: age },
            { label: '性别', value: gender },
            { label: '自定义人群', value: person },
            { label: '排除人群', value: this.baseInfo.excludedCustomAudience },
            { label: '联网方式', value: this.txggDict.networkType(this.baseInfo.networkType) },
            { label: '排除已转化用户', value: this.baseInfo.excludedConvertedAudience },
          ],
        },
      ];
      this.groupList = groupList;
    },
  },
};
</script>

<style lang="less" scoped>
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
</style>