<!--
 * @Author: LiuXin
 * @Date: 2021-10-28 15:09:04
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-29 17:40:37
-->
<template>
  <div class="txgg_advertise_table popularize-advertise-table" id="txgg-advertise-table" ref="tableWrapper">
    <table-with-statistic :statistic="statistic" :columns="columns" v-bind="table" :data-source="dataSource" :loading="loading" :scroll="{ x: columns.length * 150, wrapperId: 'txgg-advertise-table', otherHeight: 40 }" :row-selection="rowSelection" @change="sortAndPageChange" rowkey="campaign_id">
      <template #default="{ record, text, index, column }">
        <!-- 开关 -->
        <template v-if="column.dataIndex == 'switch'">
          <a-switch :loading="record.switchLoading" :disabled="switchStatus(record, 'disabled')" :checked="switchStatus(record, 'status')" size="small" @change="(changed, e) => switchChange(changed, e, record)" />
        </template>

        <!-- 操作 -->
        <template v-else-if="column.dataIndex == 'operate'">
          <span v-if="activeKey == 'product' && record.product_name == '未绑定产品'" style="color: #999">详情</span>
          <a v-else @click.stop="tableRowOperate('detail', record)"> 详情</a>
          <!-- <span class="cursorPoi" style="color: #2f70f4; padding: 0 24px 0 12px" @click="openHostDrawer(record)">自定义托管</span> -->
          <!-- <div class="hosting-status-icon">
                <svg-icon :icon-class="record.hostStatus ? 'hosting_icon' : 'unmanaged_icon'" class-name="status-icon"></svg-icon>
              </div> -->
        </template>

        <!-- 推广计划状态 -->
        <template v-else-if="column.dataIndex == 'configured_status'">
          <!-- {{ showUnitStatus(record) }} -->
          {{ switchStatus(record, 'text') }}
          <div class="status_wrapper flexAlignCenter">
            <a-tooltip class="mr10">
              <template #title>{{ record.learn_status }}</template>
              <span class="status_box" v-if="record.learn_status && record.learn_status != '0'" :class="setLearnStatusClass('learnStatus', record.learn_status)">
                <CheckCircleOutlined v-if="setLearnStatusClass('learnStatus', record.learn_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                学
              </span>
            </a-tooltip>
            <a-tooltip>
              <template #title>{{ record.compensate_status }}</template>
              <span class="status_box" v-if="record.compensate_status && record.compensate_status != '不需要赔付' && record.compensate_status != '0'" :class="setLearnStatusClass('compensateStatus', record.compensate_status)">
                <SafetyOutlined v-if="setLearnStatusClass('compensateStatus', record.compensate_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                保
              </span>
            </a-tooltip>
          </div>
        </template>

        <!-- 投放时间 -->
        <template v-else-if="column.dataIndex == 'time_series'">
          <span v-html="setPostTime(record.formatTimeSeries)"></span>
        </template>

        <!-- 可跳转到其他tab的且可以编辑列 -->
        <template v-else-if="columnShow(column) == 'jumpable&Editable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }">
            <a @click.stop="toNextTab(record)">{{ text }}</a>
          </editable-column>
        </template>

        <!--仅可跳转的列 -->
        <template v-else-if="columnShow(column) == 'onlyJumpable'">
          <a @click.stop="toNextTab(record)">{{ text }}</a>
          <p v-if="activeKey == 'account'">ID：{{ record.advertiser_id }}</p>
        </template>

        <!-- 仅可以编辑 -->
        <template v-else-if="columnShow(column) == 'onlyEditable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }"> </editable-column>
        </template>

        <!-- 创意图 -->
        <template v-else-if="column.dataIndex == 'creativity'">
          <div :class="[(column.width = 250), 'flexAlignCenter']">
            <div style="flex-shrink: 0; margin-right: 8px">
              <video v-if="record.type == 'VIDEO'" controls :src="record.previewUrl" style="width: 80px; height: 45px; object-fit: scale-down"></video>
              <img v-else :src="record.previewUrl" :alt="record.ad_name" style="width: 80px; height: 45px; object-fit: scale-down" />
            </div>
            <p class="textHide-2rows" :title="record.title">{{ record.title || '-' }}</p>
          </div>
        </template>

        <!-- 广告出价 -->
        <template v-else-if="column.title == '广告出价'">
          {{ setBidColumn(record) }}
        </template>
      </template>
    </table-with-statistic>
  </div>
  <!-- 详情抽屉 -->
  <detail-drawer v-model:visible="drawerVisible" :row-data="rowData"></detail-drawer>
  <!-- 自定义托管抽屉 -->
  <host-drawer v-model:visible="hostDrawerVisible" :row-data="rowData" channel="GDT"></host-drawer>
</template>

<script>
import { SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons-vue';
import DetailDrawer from '../detail/index.vue';
import HostDrawer from '@/views/popularize/components/HostDrawer.vue';
import TableWithStatistic from '@/components/TableWithStatistic/index.vue';
import { getTableData } from '@/api/popularize/txgg/index.js';
import EditableColumn from './EditableColumn.vue';
import columns from '@/views/popularize/assets/txggColumns.js';
import { columnMap } from '@/utils/utils.js';
import moment from 'moment';
export default {
  name: 'TxggTabsTable',
  components: { DetailDrawer, EditableColumn, HostDrawer, SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined, TableWithStatistic },
  props: {
    columns: { type: Array, default: () => [] },
    selectedRows: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    activeKey: { type: String, default: 'product' },
  },
  provide() {
    return { getTabActiveKey: () => this.activeKey, getMediaAccount: 'TXGG' };
  },
  inject: ['getBaseFilter', 'txggDict'],
  emits: ['update:selectedRows', 'update:loading', 'switchChange', 'update:activeKey', 'updateFreshTime'],
  data() {
    return {
      selectedRowKeys: [], //当前选中行id
      rowData: {}, //点击详情的所在的行数据
      drawerVisible: false, //抽屉是否打开
      hostDrawerVisible: false, //自定义托管抽屉是否打开
      dataSource: [], //表格数据
      table: {
        bordered: true,
        size: 'small',
        showExpanded: false,
        pagination: { current: 1, pageSize: 10, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
      baseFilters: {},
      bidDictionary: columns.bidDictionary,
      statistic: {},
    };
  },
  computed: {
    // 表格可选择配置
    rowSelection() {
      if (this.activeKey == 'product') {
        return null;
      }
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.$emit('update:selectedRows', selectedRows);
        },
        getCheckboxProps: record => {
          return {
            // 状态是已删除的行不可选择
            disabled: record.ctl_status && this.switchStatus(record, 'disabled'),
          };
        },
      };
    },
    // 启停状态
    switchStatus() {
      // 当为NORMAL的时候表示开启；当为true的时候表示已删除，这一行除了跳转tab，其他都不可操作
      // ctl_status: 'NORMAL:true' ,'NORMAL:false', 'SUSPEND:true','SUSPEND:fasle'
      // mode: disabled:是否可编辑   status：状态
      return (record, mode) => {
        if (mode == 'disabled') {
          return record.ctl_status?.split(':')[1] == 'true';
        } else if (mode == 'status') {
          return record.ctl_status?.split(':')[0] == 'NORMAL';
        } else if (mode == 'text') {
          return {
            'SUSPEND:false': '暂停中',
            'NORMAL:false': '启用中',
            'SUSPEND:true': '已删除',
            'NORMAL:true': '已删除',
          }[record.ctl_status];
        }
      };
    },
  },
  methods: {
    /**
     * @description: 设置状态icon类名
     * @param {*} type 状态类别
     * @param {*} status 状态
     * @return {*}
     */
    setLearnStatusClass(type, status) {
      const list = {
        learnStatus: {
          学习失败: 'status_box_grey',
          学习成功: 'status_box_green',
          学习中: 'status_box_blue',
        },
        compensateStatus: {
          已赔付完成: 'status_box_grey',
          已失效: 'status_box_grey',
          成本保障生效中: 'status_box_green',
          成本保证确认中: 'status_box_blue',
        },
      };
      return list[type]?.[status] || 'status_box_grey';
    },
    /** 設置广告状态顯示
     * @description:
     * @param {*} record
     * @return {*}
     */
    showUnitStatus(record) {
      if (record.status) {
        const statusList = record.status.split(':');
        const unitStatusOptions = [
          { value: -1, label: '不限' },
          { value: 1, label: '计划已暂停' },
          { value: 3, label: '计划超预算' },
          { value: 6, label: '余额不足' },
          { value: 11, label: '审核中' },
          { value: 12, label: '审核未通过' },
          { value: 14, label: '已结束' },
          { value: 15, label: '已暂停' },
          { value: 17, label: '组超预算' },
          { value: 19, label: '未达投放时间' },
          { value: 20, label: '有效' },
          { value: 22, label: '不在投放时段' },
        ];
        const studyStatusOptions = [
          { value: 'LEAENING_STATUS_WIP', label: '学习中' },
          { value: 'LEAENING_STATUS_FINISHED', label: '学习结束' },
          { value: 'LEAENING_STATUS_FAILED', label: '学习失败' },
          { value: 'LEAENING_STATUS_UNKNOWN', label: '未知' },
        ];
        const compensateStatusOptions = [
          { value: 'COST_GUARANTEE_STATUS_NONE', label: '无成本保障状态' },
          { value: 'COST_GUARANTEE_STATUS_EFFECTIVE', label: '成本保障生效中' },
          { value: 'COST_GUARANTEE_STATUS_FAILED', label: '成本保障已失效' },
          { value: 'COST_GUARANTEE_STATUS_FINISHED', label: '成本保障已结束' },
          { value: 'COST_GUARANTEE_STATUS_CONFIRMING', label: '成本保障确认中' },
          { value: 'COST_GUARANTEE_STATUS_SUCCEEDED', label: '超成本赔付已完成' },
        ];
        record.learn_status = columnMap(studyStatusOptions, statusList[1], 'value', 'label') || statusList[1] || null;
        record.compensate_status = columnMap(compensateStatusOptions, statusList[2], 'value', 'label') || statusList[2] || null;
        return record.status || statusList[0] || '-';
      } else {
        return '-';
      }
    },
    /**
     * @description: 打开自定义托管侧边栏
     * @param {*} record 选中的行数据
     * @return {*}
     */
    openHostDrawer(record) {
      this.drawerVisible = false;
      this.hostDrawerVisible = true;
      this.rowData = record;
    },
    setPostTime(formatTimeSeries) {
      let str = '-';
      const arr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
      if (formatTimeSeries) {
        if (arr.every(item => formatTimeSeries[item].includes('不限'))) {
          str = '不限';
        } else if (arr.every(item => formatTimeSeries[item] == formatTimeSeries['星期一'])) {
          str = formatTimeSeries['星期一'];
        } else {
          str = `星期一：${formatTimeSeries?.['星期一']}<br>
                星期二：${formatTimeSeries?.['星期二']}<br>
                星期三：${formatTimeSeries?.['星期三']}<br>
                星期四：${formatTimeSeries?.['星期四']}<br>
                星期五：${formatTimeSeries?.['星期五']}<br>
                星期六：${formatTimeSeries?.['星期六']}<br>
                星期日：${formatTimeSeries?.['星期日']}<br>  `;
        }
      }
      return str;
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt && returnOpt[text];
      }
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */
    setBidColumn(record) {
      const cjList = record.cj?.split('|');
      if (cjList && cjList.length > 0) {
        const bidMode = this.columnMap(this.bidDictionary.bid_mode, cjList[1]);
        const optimizationGoal = this.columnMap(this.bidDictionary.optimization_goal, cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == 'SYSTEMATIC') {
          return `自动出价（${bidMode}/${optimizationGoal}）`;
        } else {
          const bidShow = `${bidMode || ''} ${cjList[2] / 100 || 0}元${optimizationGoal ? '/' : ''}${optimizationGoal || ''}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
    // 获取表格数据 filters：筛选条件，sorterFilters：排序条件
    async getTableData({ filters, sorterFilters }) {
      this.$emit('update:loading', true); //tableLoading开始
      //生成请求参数
      const getParams = () => {
        if (filters) {
          this.baseFilters = { ...filters };
        }
        // 排序数据 （产品和账户默认降序）
        if (sorterFilters) {
          this.baseFilters.order = sorterFilters.order;
          this.baseFilters.orderField = sorterFilters.orderField;
        } else if (['product', 'account'].includes(this.activeKey)) {
          this.baseFilters.order = 'desc';
          this.baseFilters.orderField = 'cost';
        } else {
          this.baseFilters.order = undefined;
          this.baseFilters.orderField = undefined;
        }
        let isNotData = this.baseFilters.isNotData ? 1 : 0;
        const { current: page, pageSize: limit } = this.table.pagination;
        const elseParams = {
          isNotData: isNotData,
          page,
          limit: limit,
          tempName: this.baseFilters.tempName || '自定义列表', //模板
          userId: this.$store.state.oauth.userInfo.userId,
        };
        const params = { ...this.baseFilters, ...elseParams, mediaChannel: 'GDT' };
        return params;
      };
      const params = getParams();
      try {
        sessionStorage.setItem('txggGetTableParams', JSON.stringify(params));
        const res = await getTableData(params, this.activeKey);
        const data = res.data?.list || [];
        const rowKeyDict = {
          product: 'key',
          account: 'advertiser_id',
          manage: 'campaign_id',
          advertise: 'adgroup_id',
          creativity: 'ad_id',
        };
        const rowkey = rowKeyDict[this.activeKey];
        const statistics = res.data?.statistics || { key: rowkey };
        if (res.data?.statistics?.data_time) {
          const time = moment(res.data.statistics.data_time).format('YYYY-MM-DD HH:mm:ss');
          this.$emit('updateFreshTime', time);
        }

        const columnsDataIndexs = this.columns.map(column => column.dataIndex);
        columnsDataIndexs.forEach(key => (statistics[key] = statistics[key] || '-'));
        const key = this.columns.find(column => column.dataIndex != 'switch')?.dataIndex;
        statistics[key] = `总计：${res.data?.totalCount || 0}`;
        this.statistic = statistics;

        data.forEach((item, index) => {
          // 广告状态
          if (item.status) {
            item.status = this.txggDict.adStatus(item.status);
          }
          // 推广目标
          if (item.promoted_object_type) {
            item.promoted_object_type = this.txggDict.promotedObjectType(item.promoted_object_type);
          }
          // 广告版位
          if (item.site_set) {
            const sites = JSON.parse(item.site_set);
            const siteArr = sites.map(item => this.txggDict.siteSet(item));
            item.site_set = siteArr.join('，');
          }
          // 账户日预算
          if (item.bugdet == 0) {
            item.bugdet = '不限';
          } else if (item.bugdet) {
            item.bugdet += ' 元/天';
          }
          // 广告日预算
          if (item.ad_daily_budget == 0) {
            item.ad_daily_budget = '不限';
          } else if (item.ad_daily_budget) {
            item.ad_daily_budget += ' 元/天';
          }
          // 推广计划日预算
          if (item.campaign_daily_budget == 0) {
            item.campaign_daily_budget = '不限';
          } else if (item.campaign_daily_budget) {
            item.campaign_daily_budget += ' 元/天';
          }
          // 推广计划总预算
          if (item.total_budget == 0) {
            item.total_budget = '不限';
          } else if (item.total_budget) {
            item.campaign_daily_budget += ' 元/天';
          }
          // 创意状态
          if (item.system_status) {
            item.system_status = this.txggDict.systemStatusDict(item.system_status);
          }
          // 投放日期
          if (item.begin_date) {
            const list = item.begin_date.split('|');
            let date = '-';
            if (list?.length) {
              date = list[0] || list[1];
            }
            item.begin_date = date;
          }
          item.key = item[rowkey] || this.$UUID.v1();
        });
        this.dataSource = data;
        this.table.pagination.total = res.data?.totalCount || 0;
      } catch (err) {
        console.log(err);
      } finally {
        this.$emit('update:loading', false);
      }
    },
    // 表格分页和排序变化
    sortAndPageChange(pagination, filters, sorter) {
      // 除非更改页数，其他的筛选条件变化都会将当前页重置为1
      if (this.table.pagination.current != pagination.current) {
        this.table.pagination.current = pagination.current;
      } else {
        this.table.pagination.current = 1;
      }
      this.table.pagination.pageSize = pagination.pageSize;
      const sorterDict = { ascend: 'asc', descend: 'desc' };
      let sorterFilters = {
        order: this.baseFilters.order,
        orderField: this.baseFilters.orderField,
      };
      if (sorter.order) {
        sorterFilters = { orderField: sorter.field, order: sorterDict[sorter.order] };
      }
      this.getTableData({ sorterFilters: sorterFilters });
    },
    // 表格行操作(type:detail/edit)
    tableRowOperate(type, rowData) {
      this.rowData = rowData;
      if (type == 'detail' && this.drawerVisible) {
        //
      } else if (!this.drawerVisible) {
        this.hostDrawerVisible = false;
        this.drawerVisible = true;
      }
    },
    // 启停
    switchChange(changed, e, record) {
      this.$emit('switchChange', changed, record);
    },
    // 跳转到下一个tab页
    toNextTab(record) {
      const tabs = ['product', 'account', 'manage', 'advertise', 'creativity'];
      const nextTab = tabs[tabs.indexOf(this.activeKey) + 1];
      this.$emit('update:activeKey', nextTab);
      const filterOptions = this.getBaseFilter();
      switch (nextTab) {
        case 'account':
          filterOptions.productCodes = record.productCode;
          filterOptions.filterName && !filterOptions.productCodes && (filterOptions.productName = record.product_name);
          break;
        case 'manage':
          filterOptions.filterName = 'advertiser';
          filterOptions[filterOptions.filterName] = record.advertiser_id;
          break;
        case 'advertise':
          filterOptions.filterName = 'campaign';
          filterOptions[filterOptions.filterName] = record.campaign_id;
          break;
        case 'creativity':
          filterOptions.filterName = 'adgroup';
          filterOptions[filterOptions.filterName] = record.adgroup_id;
          break;
        default:
          break;
      }
    },
    // 控制列是否展示
    columnShow(column, record) {
      // 可以跳转到其他tab的列
      let jumpableColumn = { product: 'product_name', account: 'advertiser_name', manage: 'campaign_name', advertise: 'adgroup_name' };
      // 可编辑列
      let editableColumn = {
        account: ['bugdet', 'remark'],
        manage: ['campaign_name', 'campaign_daily_budget'],
        advertise: ['adgroup_name', 'cj', 'begin_date+end_date+first_day_begin_time', 'deep_conversion_spec-deep_conversion_behavior_spec-goal+deep_conversion_spec-deep_conversion_behavior_spec-bid_amount', 'ad_daily_budget'],
      };
      let flag = 'else';
      if (column.dataIndex == jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'jumpable&Editable'; //可跳转且可编辑
        column.width = '20em';
      } else if (column.dataIndex == jumpableColumn[this.activeKey] && !editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'onlyJumpable'; // 可跳转但不能编辑
        column.width = '20em';
      } else if (column.dataIndex != jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'onlyEditable'; // 不可跳转但能编辑
      } else if (column.dataIndex == 'advertiser_id' && this.activeKey == 'account') {
        flag = '';
      }
      return flag;
    },
  },
};
</script>
