<!--
 * @Author: LiuXin
 * @Date: 2021-10-11 16:57:00
 * @LastEditors: Yran
 * @LastEditTime: 2022-01-05 11:04:14
-->
<template>
  <div class="txgg_wrapper popularize-wrapper w100 h100">
    <!-- 筛选 -->
    <div class="txgg_filter_wrapper filter_row">
      <txgg-filter filter-source="TXGG" :disabled="disabled" :filter-menu="filterMenu" v-model:filter-options-list="filterOptions" :select-tab-key="selectTabKey" @toBottom="toBottom" @onSelectFocus="onSelectFocus" @getFilterOptions="getFilterOptions" @handleSearch="handleSearch"></txgg-filter>
    </div>
    <!-- 趋势图 -->
    <div class="txgg_chart_wrapper trend_wrapper w100 bcg_w">
      <base-chart :chart-option="options" :show-fold="true" title="趋势图">
        <template #filter>
          <a-select label-in-value v-model:value="trendFilter.indexOneKey" @change="getTrendData()" :options="selectOptions" v-bind="$filterOptions"></a-select>
          <a-select label-in-value v-model:value="trendFilter.indexTwoKey" @change="getTrendData()" :options="selectOptions" v-bind="$filterOptions"></a-select>
        </template>
      </base-chart>
    </div>
    <!-- 表格 -->
    <div class="txgg_tab_wrapper popularize-tab-wrapper w100 bcg_w">
      <txgg-tabs :base-filter="baseFilter" @selectTabs="selectTabs" :all-indicator="allIndicator" ref="txggTabs"></txgg-tabs>
    </div>
  </div>
</template>

<script>
import BaseChart from '../components/BaseChart.vue';
import TxggFilter from '../components/BaseFilters.vue';
import TxggTabs from './components/TxggTabs.vue';

import columns from '@/views/popularize/assets/txggColumns.js';
import echartsOptions from '../assets/echartsOptions.js';
import { getTrendList, getIndicatorList } from '@/api/popularize/txgg/index.js';
import { txggDict } from '../assets/dict.js';
import moment from 'moment';
import _ from 'lodash';
export default {
  components: { TxggTabs, BaseChart, TxggFilter },
  data() {
    return {
      options: {},
      filterMenu: columns.filterMenus,
      filterMenuSource: columns.filterMenus,
      baseFilter: {},
      filterOptions: {
        filterName: 'productName',
        advancedList0: {},
        advanced: null,
        uploadTime: [moment(), moment()],
      },
      trendFilter: {
        indexOneKey: { value: 'cost', label: '花费', key: 'cost' },
        indexTwoKey: { value: 'ctr', label: '点击率', key: 'ctr' },
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      selectTabKey: 'product',
      allIndicator: [], //所有指标
      selectOptions: [], //趋势图筛选项（非属性指标）
      timeLine: 'REPORTING_TIME', //口径
      timer: null,
      disabled: false, //筛选项禁用
    };
  },
  provide() {
    return {
      getBaseFilter: () => this.filterOptions,
      mediaChannel: 'GDT',
      txggDict,
    };
  },
  async created() {
    await this.getIndicatorList();
  },
  mounted() {
    this.setFilterMenus();
  },

  methods: {
    async init() {
      this.getCustomerList();
      this.getProductList();
    },
    /** 获取表格tab
     * @param {*}
     * @return {*}
     */
    selectTabs(tabKey) {
      this.selectTabKey = tabKey;
      this.disabled = tabKey == 'creativity';
    },
    /** 设置筛选项
     * @param {*}
     * @return {*}
     */
    setFilterMenus(tabKey) {
      switch (tabKey) {
        case 'product':
          this.filterMenu = this.filterMenuSource.filter(item => {
            return item.label == '产品数据' || item.label == '高级筛选';
          });
          this.filterOptions.filterName = 'productName';
          this.filterOptions.advertiser = null;
          this.filterOptions.campaign = null;
          this.filterOptions.adgroup = null;
          delete this.filterOptions.advertiser;
          delete this.filterOptions.campaign;
          delete this.filterOptions.adgroup;
          break;
        case 'account':
          this.filterMenu = this.filterMenuSource.filter(item => {
            return item.label == '产品数据' || item.label == '账户数据' || item.label == '高级筛选';
          });
          this.filterOptions.filterName = 'advertiser';
          this.filterOptions.campaign = null;
          this.filterOptions.adgroup = null;
          delete this.filterOptions.campaign;
          delete this.filterOptions.adgroup;
          break;
        case 'manage':
          this.filterMenu = this.filterMenuSource.filter(item => {
            return item.label == '产品数据' || item.label == '账户数据' || item.label == '推广计划' || item.label == '高级筛选';
          });
          this.filterOptions.filterName = 'campaign';
          this.filterOptions.adgroup = null;
          delete this.filterOptions.adgroup;
          break;
        case 'advertise':
          this.filterMenu = _.cloneDeep(this.filterMenuSource);
          this.filterOptions.filterName = 'adgroup';
          break;
        case 'creativity':
          this.filterOptions.filterName = 'productName';
          break;
      }

      !tabKey &&
        this.filterMenuSource.forEach(item => {
          if (item.value != '产品数据' && item.value != '账户数据' && item.value != '高级筛选') {
            item.filter?.forEach(filter => {
              const storageList = ['planCodes', 'targetTypeCodes', 'advertiseCodes', 'advertiseStatusCodes', 'promoteCodes', 'expansionCodes', 'bidCodes', 'learnCodes', 'claimCodes'];
              storageList.includes(filter.value) && this.getData(filter);
            });
          }
        });
    },
    /** 获取筛选项
     * @param {*} filterOptions
     * @return {*}
     */
    getFilterOptions(filterOptions) {
      // filterOptions.isNotData = filterOptions.isNotData ? 1 : 0;
      if (filterOptions.filterName && filterOptions.filterValue) {
        filterOptions[filterOptions.filterName] = filterOptions.filterValue;
        filterOptions.filterValue = null;
      }
      const obj = {};
      const keys = Object.keys(filterOptions);
      const needArrParam = ['customerCodes', 'productCodes', 'companyNames', 'deptIds', 'userIds', 'planCodes', 'targetTypeCodes', 'advertiseCodes', 'advertiseStatusCodes', 'expansionCodes', 'bidCodes', 'learnCodes', 'claimCodes'];
      keys.forEach(key => {
        if (needArrParam.includes(key)) {
          filterOptions[key] && (obj[key] = Array.isArray(filterOptions[key]) ? filterOptions[key] : [filterOptions[key]]);
        } else {
          filterOptions[key] && (obj[key] = filterOptions[key]);
        }
      });
      obj.planCodes?.[0] == 'true' && (obj.planCodes = ['true', 'NORMAL', 'SUSPEND']);
      obj.planCodes?.[0] == 'false' && (obj.planCodes = ['false', 'NORMAL', 'SUSPEND']);
      this.baseFilter = obj;
      let _this = this;
      if (!this.timer) {
        _this.timer = setTimeout(() => {
          _this.getTrendData();
          _this.timer = null;
        }, 300);
      }
    },
    /** 下拉框展开
     * @param {*} filter
     * @return {*}
     */
    onSelectFocus(filter) {
      this.getData(filter);
    },
    /** 下拉框搜索
     * @param {*} value
     * @param {*} filter
     * @return {*}
     */
    handleSearch(value, filter) {
      filter.params && (filter.params[filter.searchValue] = value);
      this.getData(filter);
    },
    /** 获取对应筛选项对应的索引
     * @param {*} value
     * @return {*}
     */
    async getSelectOptionsIndex(value) {
      let index1 = 0;
      let index2 = 0;
      this.filterMenu?.forEach((item, index) => {
        if (item.type != 'array') {
          let obj = item.filter.forEach((childItem, childIndex) => {
            if (childItem.value == value) {
              [index1, index2] = [index, childIndex];
            }
          });
        } else {
        }
      });
      return [index1, index2];
    },
    /** 滚动触底
     * @param {*} filter
     * @return {*}
     */
    async toBottom(filter) {
      if (filter.page >= filter.allPages) {
        filter.loadOver = true;
        return;
      }
      filter.loadOver = false;
      filter.page = parseInt(filter.page) + 1;
      this.getData(filter);
    },
    // 获取所有指标
    async getIndicatorList() {
      let postData = { mediaChannel: 'GDT', moduleName: '推广管理' };
      let res = await getIndicatorList(postData);
      this.allIndicator = res.data || [];
      const selectOptions = [];
      this.allIndicator?.forEach(item1 => {
        if (item1.name != '属性指标') {
          item1.info.forEach(item2 => {
            item2.info.forEach(item3 => {
              const { columnKey: value, columnValue: label } = item3;
              selectOptions.push({ value, label });
            });
          });
        }
      });
      this.selectOptions = selectOptions;
      this.$nextTick(() => {
        this.$refs.txggTabs?.init();
      });
    },
    /** 获取筛选数据
     * @param {*} filter
     * @return {*}
     */
    async getData(filter, callback) {
      let postData = {
        page: filter.page,
        limit: filter.limit,
        ...filter.params,
      };
      const storageList = ['planCodes', 'targetTypeCodes', 'advertiseCodes', 'advertiseStatusCodes', 'promoteCodes', 'expansionCodes', 'bidCodes', 'learnCodes', 'claimCodes'];
      // 清除未区分渠道的缓存
      localStorage.removeItem(filter.value + 'Options');
      if (storageList.includes(filter.value) && localStorage[filter.value + 'OptionsGDT']) {
        filter.loading = false;
        filter.loadOver = true;
        filter.options = JSON.parse(localStorage[filter.value + 'OptionsGDT']);
        return;
      }
      filter.loading = true;
      let res = await filter.func(postData);
      if (res.code == 0) {
        let resData = res.data?.list || res.page?.list;
        resData = resData?.map(
          item =>
            item && {
              label: item[filter.listLabel],
              value: item[filter.listValue],
            }
        );
        if (filter.params?.[filter.searchValue] || !filter.lazyLoad) {
          filter.options = resData;
        } else {
          filter.options = filter.options.concat(resData);
        }
        storageList.includes(filter.value) && (localStorage[filter.value + 'OptionsGDT'] = JSON.stringify(filter.options));
        filter.loading = false;
        filter.loadOver = true;
        filter.page = res.data.currPage;
        filter.allPages = res.data.totalPage;
      }
    },
    // 获取趋势图数据
    async getTrendData(timeLine) {
      this.timeLine = timeLine || this.timeLine;
      let res = {};
      let params = {};
      let data = { series1: {}, series2: {}, compare1: {}, compare2: {}, xAxisData: [] };
      let indexOneData = [];
      let indexTwoData = [];
      let indexOneCompare = [];
      let indexTwoCompare = [];
      let xData;
      try {
        const isNotData = this.baseFilter.isNotData ? 1 : 0;
        params = {
          indexOneKey: this.trendFilter.indexOneKey.value,
          indexTwoKey: this.trendFilter.indexTwoKey.value,
          startDate: this.trendFilter.startDate,
          endDate: this.trendFilter.endDate,
          ..._.cloneDeep(this.baseFilter),
          timeLine: this.timeLine,
          isNotData,
        };
        this.formatDate(params);
        res = await getTrendList(params);
        // 生成时间
        const getTime = time => {
          if (params.startDate == params.endDate) {
            if (time < 10) {
              return `0${time}:00`;
            } else {
              return `${time}:00`;
            }
          } else {
            return time;
          }
        };
        // 生成x轴
        xData = new Set();
        if (params.startDate == params.endDate) {
          const data = [];
          for (let index = 0; index < 24; index++) {
            data.push(getTime(index));
          }
          xData = data;
        } else {
          let start = new Date(params.startDate).getTime();
          let end = new Date(params.endDate).getTime();
          let dayTime = 3600 * 24 * 1000;
          let data = [];
          for (let time = start; time <= end; time += dayTime) {
            data.push(moment(time).format('YYYY-MM-DD'));
          }
          xData = data;
        }
        data.xAxisData = xData;
        data.legend = {};
        if (!res.data) {
          const options = echartsOptions.trend(data);
          this.options = options;
          return;
        }
        // 指标一数据
        res.data.indexOne?.selectedData.forEach(item => {
          indexOneData.push([getTime(item.dateTime), item.value]);
        });
        // 指标一对比数据
        res.data.indexOne?.comparedData.forEach(item => {
          indexOneCompare.push([getTime(item.dateTime), item.value]);
        });

        // 指标二数据
        res.data.indexTwo?.selectedData.forEach(item => {
          indexTwoData.push([getTime(item.dateTime), item.value]);
        });
        // 指标二对比数据
        res.data.indexTwo?.comparedData.forEach(item => {
          indexTwoCompare.push([getTime(item.dateTime), item.value]);
        });
      } catch (error) {
        console.log(error);
      }
      const index1 = this.trendFilter.indexOneKey.label;
      const index2 = this.trendFilter.indexTwoKey.label;
      const compare1 = moment(params.startDate).subtract(1, 'days').format('YYYY-MM-DD') + index1;
      const compare2 = moment(params.startDate).subtract(1, 'days').format('YYYY-MM-DD') + index2;
      // 如果是当日，则默认显示指标一与指标一对比数据，指标二与指标二对比数据可手动控制显隐
      // 如果不是当日，则只会显示指标一与指标二
      if (params.startDate == params.endDate) {
        data.compare1 = { name: compare1, data: indexOneCompare };
        data.compare2 = { name: compare2, data: indexTwoCompare };
        data.legend = {
          data: [
            {
              name: index1,
              icon: 'path://M0 0m170.250971 0l1702.509705 0q170.250971 0 170.250971 170.250971l0 0q0 170.250971-170.250971 170.25097l-1702.509705 0q-170.250971 0-170.250971-170.25097l0 0q0-170.250971 170.250971-170.250971Z',
            },
            {
              name: compare1,
              icon: 'path://M6667.264 1024h-2.56a512 512 0 0 1-512-512 512 512 0 0 1 512-512h2.56a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM4616.704 1024h-3.072a512 512 0 0 1-512-512 512 512 0 0 1 512-512h3.072a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM2565.632 1024h-2.56a512 512 0 0 1-512-512 512 512 0 0 1 512-512h2.56a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM515.072 1024H512a512 512 0 0 1-512-512 512 512 0 0 1 512-512h3.072a512 512 0 0 1 512 512 512 512 0 0 1-512 512z',
            },
            {
              name: index2,
              icon: 'path://M0 0m170.250971 0l1702.509705 0q170.250971 0 170.250971 170.250971l0 0q0 170.250971-170.250971 170.25097l-1702.509705 0q-170.250971 0-170.250971-170.25097l0 0q0-170.250971 170.250971-170.250971Z',
            },
            {
              name: compare2,
              icon: 'path://M6667.264 1024h-2.56a512 512 0 0 1-512-512 512 512 0 0 1 512-512h2.56a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM4616.704 1024h-3.072a512 512 0 0 1-512-512 512 512 0 0 1 512-512h3.072a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM2565.632 1024h-2.56a512 512 0 0 1-512-512 512 512 0 0 1 512-512h2.56a512 512 0 0 1 512 512 512 512 0 0 1-512 512zM515.072 1024H512a512 512 0 0 1-512-512 512 512 0 0 1 512-512h3.072a512 512 0 0 1 512 512 512 512 0 0 1-512 512z',
            },
          ],
          selected: { [index2]: false, [compare2]: false },
        };
      } else {
        data.legend = { data: [index1, index2], selected: { [index2]: false } };
      }

      data.series1 = { name: index1, data: indexOneData };
      data.series2 = { name: index2, data: indexTwoData };
      const options = echartsOptions.trend(data);
      this.options = options;
    },
    // 处理时间范围
    formatDate(params) {
      if (this.baseFilter.ADUploadTIme && this.baseFilter.ADUploadTIme.length > 0) {
        params.advertiseStartDate = this.baseFilter.ADUploadTIme[0].format('YYYY-MM-DD');
        params.advertiseEndDate = this.baseFilter.ADUploadTIme[1].format('YYYY-MM-DD');
      }
      params.startDate = this.baseFilter.uploadTime?.[0].format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
      params.endDate = this.baseFilter.uploadTime?.[1].format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
    },
  },
};
</script>
<style lang="less">
@import url('../assets/popularize.less');
</style>