<!--
 * @Author: Yran
 * @Date: 2021-10-13 16:41:06
 * @LastEditors: Yran
 * @LastEditTime: 2021-10-14 10:19:51
-->
<template>
  <div class="operation_log txgg_operation_table">
    <base-table :table="table">
      <template #operateResult="{ record }">
        <a @click.stop="tableRowOperate('detail', record)"> 详情</a>
      </template>
      <template #open="{ record }">
        <UpOutlined v-if="record.open" @click="customRow(record)" />
        <DownOutlined v-else @click="customRow(record)" />
      </template>
      <template #expandedRowRender="{ record }">
        <div class="expanded_row_text">
          <p v-for="key in Object.keys(record)" :key="key">
            <span class="text_label">{{ key }}</span>
            <span class="text_value">{{ record[key] }}</span>
          </p>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/views/popularize/components/BaseTable.vue';
import columns from '@/views/popularize/assets/txggColumns.js';
import { DownOutlined, UpOutlined } from '@ant-design/icons-vue';
export default {
  name: '',
  data() {
    return {
      expandedRow: {},
      table: {
        dataSource: [],
        columns: columns.operationLog,
        bordered: true,
        size: 'small',
        expandedRowKeys: [],
        customRow: this.customRow,
        showExpanded: true,
      },
    };
  },
  components: { BaseTable, DownOutlined, UpOutlined },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格数据
    getTableData() {
      const data = [];
      for (let index = 0; index < 40; index++) {
        data.push({
          cost1: index % 3 == 0,
          cost2: 'test',
          cost3: 'test',
          cost4: 'test',
          cost5: 'test',
          cost6: 'test',
          cost7: 'test',
          cost8: 'test',
          cost9: 'test',
          key: index,
        });
      }
      data.unshift({ cost1: 0, cost2: '总计：246', cost3: '-', cost4: '-', cost5: '-', cost6: '-', cost7: '17.35亿', cost8: '', cost9: '', cost10: '', key: -1 });
      this.table.dataSource = data;
    },
    // 表格行操作(type:detail/edit)
    tableRowOperate(type, rowData) {
      this.advertise = rowData;
      if (type == 'detail') {
        this.drawerVisible = true;
      } else {
        //
      }
    },
    // 行点击事件
    customRow(record) {
      return {
        onClick: e => {
          e.stopPropagation();
          if (this.table.expandedRowKeys[0] == record.key) {
            this.table.expandedRowKeys.splice(0, 1);
            this.expandedRow.open = false;
            record.open = false;
          } else {
            this.table.expandedRowKeys.splice(0, 1, record.key);
            this.expandedRow.open = false;
            this.expandedRow = record;
            record.open = true;
          }
        },
      };
    },
  },
};
</script>

<style lang="less">
.operation_log {
  padding: 12px;
}
.txgg_operation_table {
  flex: 1;
  .ant-table-row-expand-icon-cell,
  .ant-table-expand-icon-th {
    display: none;
  }

  .expanded_row_text {
    flex: 1;
    padding: 10px;
    & > p {
      padding: 10px;
    }
    .text_label {
      display: inline-block;
      width: 10em;
      color: #666;
      font-size: 12px;
    }
    .title_value {
      color: #000;
      font-size: 12px;
    }
  }
}
</style>