/*
 * @Author: LiuXin
 * @Date: 2021-10-21 10:51:46
 * @LastEditors: chenxing
 * @LastEditTime: 2021-11-04 12:08:12
 */

import axios, { advert_prefix } from '@/utils/axios';

const apiUrl = {
  // 获取基础信息
  getBaseInfo: {
    product: '/tencent/productInfo/get?productId=', //产品
    account: '/tencent/advertiserInfo/get?advertiserId=', //账户
    manage: '/tencent/campaign?campaignId=', //推广计划
    advertise: '/tencent/adgroup?adgroupId=', //广告
    creativity: '/tencent/adcreative?adcreativeId=', //创意
  },
  // 获取操作日志
  getOperationLog: {
    account: '', //账户
    advertise: '', //广告
    manage: '', //推广计划
    creativity: '', //创意
  },
};

// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params)?.forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}

// 获取基础信息
export function getBaseInfo(params) {
  let url = advert_prefix + apiUrl.getBaseInfo[params.type] + params.id;
  return axios.get(url);
}

// 获取操作日志
export function getOperationLog(params) {
  let url = apiUrl.getOperationLog[params.type];
  delete params.type;
  return axios.get(getUrl(url, params));
}
