<!--
 * @Author: LiuXin
 * @Date: 2021-10-30 18:15:28
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-23 10:18:57
-->
<template>
  <!-- 可以编辑的列 -->
  <a-popover :title="`修改${column.title}`" trigger="click" :visible="visible">
    <template #content>
      <!-- 名称编辑 -->
      <template v-if="column.title.indexOf('名称') > -1">
        <a-textarea v-model:value="text" show-count :maxlength="60" style="width: 240px" />
      </template>
      <template v-else-if="column.title.indexOf('预算') > -1">
        <div class="editBudget">
          <a-radio-group v-model:value="updateBudgetMode" class="mb20">
            <a-radio value="noLimited">不限</a-radio>
            <a-radio value="customize">自定义</a-radio>
          </a-radio-group>
          <div v-show="updateBudgetMode == 'customize'" class="mb10">
            <a-input v-model:value="text" placeholder="请输入日预算"></a-input>
          </div>
        </div>
      </template>
      <template v-else-if="column.title == '广告出价'">
        <a-input v-model:value="cjValue" :suffix="setCjSuffix(record)" placeholder="请输入出价"></a-input>
      </template>
      <template v-else-if="column.title == '投放日期'">
        <a-date-picker v-model:value="postDate.beginDate" :disabled-date="disabledDate" v-if="postDate.checked" value-format="YYYY-MM-DD"></a-date-picker>
        <a-range-picker v-model:value="postDate.range" :disabled-date="disabledDate" v-else value-format="YYYY-MM-DD"> </a-range-picker>
        <div style="margin: 10px 0">
          <a-checkbox v-model:checked="postDate.checked">长期投放(仅设置开始日期) </a-checkbox>
        </div>
      </template>
      <template v-else-if="column.title == '深度目标出价'">
        <div class="deepPrice">
          <a-input v-model:value="text" suffix="元/激活" placeholder="请输入出价"></a-input>
          <p class="yellow">系统检测当前出价可能偏高，请确认是否符合预期</p>
          <p>建议出价 <span class="yellow">801.66~ 1,561.48</span> 元首次付费</p>
          <p class="grey mt20">曝光数据积累中，暂不支持曝光和转化预估</p>
        </div>
      </template>
      <template v-else-if="column.title == '账户备注'">
        <div class="remark">
          <a-textarea v-model:value="text" show-count :maxlength="60" style="width: 240px" />
        </div>
      </template>
      <!-- 底部按钮 -->
      <a-row type="flex" justify="center" class="mt10">
        <a-button @click="handleCancel" class="mr15">取消</a-button>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </a-row>
    </template>
    <span>
      <slot>
        <span v-if="column.title == '广告出价'">{{ setBidColumn(record) }}</span>
        <span v-else>{{ text }}</span>
      </slot>
      <!-- 总计行和状态为已删除的不可编辑 -->
      <EditOutlined v-if="isEditAble(record)" @click="visible = true" />
    </span>
  </a-popover>
</template>

<script>
import { EditOutlined } from '@ant-design/icons-vue';
import { updateAdName, advertiserUpdatePostDate, advertiserUpdateDailyBudget, adgroupUpdateDailyBudget, updateBidAmount, campaignsUpdateDailyBudget, campaignsUpdateName, updateBid, advertiserUpdateRemark } from '@/api/popularize/txgg/index.js';
import columns from '@/views/popularize/assets/txggColumns.js';
import _ from 'lodash';
import moment from 'moment';
export default {
  name: 'EditableColumn',
  components: { EditOutlined },
  props: {},
  data() {
    return {
      record: this.$attrs.record,
      text: this.$attrs.text,
      index: this.$attrs.index,
      column: this.$attrs.column,
      editable: this.$attrs.editable,
      visible: false,
      cjValue: null,
      postDate: { checked: false, range: [], beginDate: '' }, //投放日期数据
      updateBudgetMode: this.$attrs.text == '不限' ? 'noLimited' : 'customize',
      activeKey: this.$attrs.activeKey,
      bidDictionary: columns.bidDictionary,
    };
  },
  watch: {
    $attrs: {
      handler(newVal) {
        this.record = this.$attrs.record;
        this.text = this.$attrs.text;
        this.index = this.$attrs.index;
        this.column = this.$attrs.column;
        this.editable = this.$attrs.editable;
        this.updateBudgetMode = this.$attrs.text == '不限' ? 'noLimited' : 'customize';
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {},
  methods: {
    /**
     * @description: 设置禁用时间
     * @param {*} current
     * @return {*}
     */
    disabledDate(current) {
      return current <= moment().endOf('day');
    },
    async handleOk() {
      let params = {};
      let res = {};
      const title = this.column.title;
      // 推广tab页下单独操作
      if (this.column.title == '推广计划预算') {
        params = [{ campaignId: this.record.campaign_id, dailyBudget: this.updateBudgetMode == 'noLimited' ? 0 : (this.text + '')?.split(' 元')?.[0] * 100 }];
        res = await campaignsUpdateDailyBudget(params);
      } else if (this.column.title == '推广计划名称') {
        params = [{ campaignsId: this.record.campaign_id, campaignName: this.text }];
        res = await campaignsUpdateName(params);
      }

      // 广告tab页下单独操作
      else if (this.column.title == '广告名称') {
        params = { adgroupId: this.record.adgroup_id, adgroupName: this.text };
        res = await updateAdName(params);
      } else if (this.column.title == '广告出价') {
        params = [{ adgroupId: this.record.adgroup_id, number: this.updateBudgetMode == 'noLimited' ? 0 : this.cjValue, type: 6 }];
        res = await updateBidAmount(params);
      } else if (this.column.title == '广告日预算') {
        params = [{ adGroupId: this.record.adgroup_id, number: this.updateBudgetMode == 'noLimited' ? 0 : (this.text + '')?.split(' 元')?.[0], type: 1 }];
        res = await adgroupUpdateDailyBudget(params);
      } else if (this.column.title == '投放日期') {
        let params = { adgroupIds: [this.record.adgroup_id], beginDate: this.postDate.range[0], endDate: this.postDate.range[1] };
        if (this.postDate.checked) {
          params.beginDate = this.postDate.beginDate;
          delete params.endDate;
        }
        res = await advertiserUpdatePostDate(params);
      } else if (this.column.title == '深度目标出价') {
        params = [{ adGroupId: this.record.adgroup_id, number: this.updateBudgetMode == 'noLimited' ? 0 : this.text, type: 1 }];
        res = await updateBid(params);
      }

      // 账户tab页下单独操作
      else if (title == '账户预算' && this.activeKey == 'account') {
        params = [{ advertiserId: this.record.advertiser_id, dailyBudget: this.updateBudgetMode == 'noLimited' ? 0 : (this.text + '')?.split(' 元')?.[0], type: 1 }];
        res = await advertiserUpdateDailyBudget(params);
      } else if (this.column.title == '账户备注' && this.activeKey == 'account') {
        const params = [{ advertiserId: this.record.advertiser_id, remark: this.text }];
        const res = await advertiserUpdateRemark(params);
        if (res.code == 0) {
          this.visible = false;
          this.$message.success('编辑成功');
        } else {
          this.$message.error('编辑失败，请重试！');
        }
      }
      this.$message.success('编辑成功');
      this.visible = false;
    },
    handleCancel() {
      this.record[this.column.dataIndex] = this.$attrs.text;
      this.text = this.$attrs.text;
      this.visible = false;
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */
    setBidColumn(record) {
      const cjList = record.cj?.split('|');
      if (cjList && cjList.length > 0) {
        const bidMode = this.columnMap(this.bidDictionary.bid_mode, cjList[1]);
        const optimizationGoal = this.columnMap(this.bidDictionary.optimization_goal, cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == 'SYSTEMATIC') {
          return `自动出价（${bidMode}/${optimizationGoal}）`;
        } else {
          const bidShow = `${bidMode || ''} ${cjList[2] / 100 || 0}元${optimizationGoal ? '/' : ''}${optimizationGoal || ''}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
    /** 设置出价编辑尾部提示
     * @param {*} record
     * @return {*}
     */
    setCjSuffix(record) {
      const cjList = record.cj?.split('|');
      const optimizationGoal = cjList && cjList.length > 0 ? this.columnMap(this.bidDictionary.optimization_goal, cjList[3]) : '激活';
      return '元/' + optimizationGoal;
    },
    isEditAble(record) {
      const cjList = record.cj?.split('|');
      const smartBidType = cjList && cjList.length > 0 ? cjList[4] : 'CUSTOM';
      if (!this.cjValue) {
        this.cjValue = _.cloneDeep(cjList && cjList.length > 0 ? cjList[2] / 100 : 0);
      }
      return this.editable && !(this.column.title == '广告出价' && smartBidType == 'SYSTEMATIC');
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt && returnOpt[text];
      }
    },
  },
};
</script>

<style scoped lang="less">
.yellow {
  color: rgb(245, 159, 112);
}
.grew {
  color: #999;
}
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
.deepPrice {
  width: 300px;
}
</style>
